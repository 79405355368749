<template>
  <div class="privacy">
    <p
      class="title"
      style="
        margin-top: 0pt;
        margin-bottom: 14pt;
        text-align: center;
        widows: 2;
        orphans: 2;
      "
    >
      <span style="font-family: 微软雅黑">Privacy Policy</span>
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: left;
        widows: 2;
        orphans: 2;
      "
    >
      <span style="font-family: 微软雅黑">Effective Date: April 2, 2022</span
      ><br /><span style="font-family: 微软雅黑"
        >Loanism is an instant loan app operated by Dolphin Lending
        Investor, inc. Organized and existing in the Philippines, (“Loanism”,
        “we”, “us” or “our”) takes your privacy very seriously.</span
      ><br /><span style="font-family: 微软雅黑"
        >This privacy policy describes what information we collect from you, the
        purposes for which we collect and process it, how we use it, who we
        share it with, how long we retain it, and your rights in relation to it.
        You should read this Privacy Policy in its entirety.</span
      ><br /><span style="font-family: 微软雅黑"
        >This Privacy Policy applies in connection with your use of the
        Loanism Android mobile application found here (the “Loanism
        App”) and your use of our services through the Loanism App or
        otherwise.</span
      >
    </p>
    <h4
      style="
        margin-top: 14pt;
        margin-bottom: 14pt;
        text-align: left;
        widows: 2;
        orphans: 2;
        font-size: 13.5pt;
      "
    >
      <span style="font-family: 微软雅黑"
        >1. What data does Loanism collect and process?</span
      >
    </h4>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: left;
        widows: 2;
        orphans: 2;
      "
    >
      <span style="font-family: 微软雅黑"
        >• Identification data (e.g., first name, surname, date, and place of
        birth);</span
      ><br /><span style="font-family: 微软雅黑"
        >• Data provided by you through your loan application (e.g., information
        about your income or employment, your proposed use of your loan
        proceeds, customer-provided information about your mobile device);</span
      ><br /><span style="font-family: 微软雅黑"
        >• Contact list details (e.g., address, phone number, alternate phone
        number, email address);</span
      ><br /><span style="font-family: 微软雅黑"
        >• Mobile device data (e.g., SIM, IMEI, or other device identifiers,
        type of device, device operating system, device settings, user account
        information for your mobile device or Google PlayStore account, the name
        and network information of your mobile network provider, device
        specifications (such as screen size, resolution, etc.), information
        about network proximity);</span
      ><br /><span style="font-family: 微软雅黑"
        >• Location data (e.g., mobile device location, time zone
        setting);</span
      ><br /><span style="font-family: 微软雅黑"
        >• Transaction data and financial information (e.g., loans, transfers,
        payments, loan requests, tax information);</span
      ><br /><span style="font-family: 微软雅黑"
        >• Loanism App usage (e.g., traffic (volume) data, information about
        your usage of the Loanism App);</span
      ><br /><span style="font-family: 微软雅黑"
        >• Data related to your communications with Loanism (e.g., your
        communications with Loanism via in-app chat, email, telephone, or other
        channels); and Loanism may collect your Information from You, when
        downloading the Loanism App and/or indicate that you want to apply for
        a loan;</span
      ><br /><span style="font-family: 微软雅黑"
        >• Your other interactions with us, including information you may
        voluntarily share with our customer support team or other Loanism
        employees or agents;</span
      ><br /><span style="font-family: 微软雅黑"
        >• Your mobile device (e.g., mobile device data, location data,
        transaction data, Loanism App usage, and/or phone data as more fully
        described above);</span
      ><br /><span style="font-family: 微软雅黑"
        >• Collection and processing of your Information by Loanism is
        necessary for the provision of LoanPlus’s products and services and/or
        to comply with applicable legal and regulatory requirements to which you
        and/or Loanism are subject. Apart from such cases, we do not collect
        information without your specific prior consent.</span
      ><br /><span style="font-family: 微软雅黑"
        >• Phone data (e.g., contact lists, SMS logs);</span
      ><br /><span style="font-family: 微软雅黑"
        >• We may collect some of your other Personal Information only after
        obtaining your additional explicit consent.</span
      ><br /><span style="font-family: 微软雅黑"
        >• ✔Permisslon to SMS：only for personal transactionsmay，be collected
        for anti-fraud purposes.</span
      ><br /><span style="font-family: 微软雅黑">• ✔</span
      ><span style="font-family: 微软雅黑; font-weight: bold"
        >Permisslon to Contact list:</span
      ><span style="font-family: 微软雅黑">&#xa0;</span
      ><span style="font-family: 微软雅黑"
        >the phone numbers you add to your contact list application may be
        collected。By glving us your home and/or mobile phone number. we have
        your permission to contact you or the contact list number you authorized
        us. This also allow us use text messaging, artificial or prerecorded
        voice messages and automatic dialing technology, for all purposes not
        prohibited by applicable law.Message and data rates may apply. You may
        contact us anytime to change these preferences. We may also send an
        email to any address where we reasonably believe we can contact
        you(include the reference you authorized). Some of the purposes for
        calls and messages include: suspected fraud or ldentity thef;obtaining
        information; transactions on or servicing of your account; and
        collecting on your account or collecting delinquent account . Our rights
        under this Section extend to our afliates, subsidiaries, parents,agents,
        vendors, and anyone So afliated with the owner of any note evidencing a
        loan you obtain.</span
      >
    </p>
    <h4
      style="
        margin-top: 14pt;
        margin-bottom: 14pt;
        text-align: left;
        widows: 2;
        orphans: 2;
      "
    >
      <span style="font-family: 微软雅黑"
        >2. What are the purposes for which your information is processed? How
        does Loanism use your Information?</span
      >
    </h4>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: left;
        widows: 2;
        orphans: 2;
      "
    >
      <span style="font-family: 微软雅黑"
        >• To assess your eligibility to use our products or services
        (including, but not limited to, by assessing your creditworthiness,
        determining whether you can afford a product or service you requested,
        and/or determining whether you are eligible for additional benefits of
        an existing product);</span
      ><br /><span style="font-family: 微软雅黑"
        >• To service your loan with Loanism (including, but not limited to, by
        processing your transactions and processing disbursement of your loan
        and collecting repayment of your loan);</span
      ><br /><span style="font-family: 微软雅黑"
        >• To verify your identity and/or other information you’ve provided to
        us;</span
      ><br /><span style="font-family: 微软雅黑"
        >• To detect, combat, and prevent fraud, attempted fraud, money
        laundering, and/or other illegal uses of our services;</span
      ><br /><span style="font-family: 微软雅黑"
        >• To analyze customer behavior;</span
      ><br /><span style="font-family: 微软雅黑"
        >• To administer our systems, maintain service quality, and compile
        general usage statistics;</span
      ><br /><span style="font-family: 微软雅黑"
        >• To analyze and improve our services;</span
      ><br /><span style="font-family: 微软雅黑"
        >• To troubleshoot any problems you or other customers encounter with
        LoanPlus’s services;</span
      ><br /><span style="font-family: 微软雅黑"
        >• To comply with applicable laws, regulations, and rules, such as those
        relating to “know-your-customer” and/or anti-money laundering;</span
      ><br /><span style="font-family: 微软雅黑"
        >• To the extent you have not objected to the use of your information
        for direct marketing purposes after proper notification, to send you
        marketing or advertising notices or other promotional offers;</span
      ><br /><span style="font-family: 微软雅黑"
        >• To provide service updates;</span
      ><br /><span style="font-family: 微软雅黑"
        >• To provide staff training (where we may monitor or record customer
        interactions);</span
      ><br /><span style="font-family: 微软雅黑"
        >• To interface with credit reference or fraud prevention
        agencies;</span
      ><br /><span style="font-family: 微软雅黑"
        >• To provide customer service or support;</span
      ><br /><span style="font-family: 微软雅黑"
        >• To contact you by telephone using auto dialed or prerecorded message
        calls or text (SMS) messages (if applicable) as authorized for the
        purposes described in this Privacy Policy.</span
      ><br /><span style="font-family: 微软雅黑"
        >• We process your Information for the purposes set out above on the
        following grounds:</span
      ><br /><span style="font-family: 微软雅黑"
        >• In connection with our legitimate interest in (1) providing you with
        credit and/or other financial or technological products or services, (2)
        operating our business, (3) marketing our products and services to you
        and others, and (4) administering our systems and keeping our records up
        to date;</span
      ><br /><span style="font-family: 微软雅黑"
        >• To carry out our obligations to you as a result of any contracts or
        agreements entered into between you and us (i.e., where necessary for
        the adequate performance of our contract with you and/or to take steps
        requested by you prior to entering into a contract with you); and/or
        with your prior consent.</span
      >
    </p>
    <h4
      style="
        margin-top: 14pt;
        margin-bottom: 14pt;
        text-align: left;
        widows: 2;
        orphans: 2;
      "
    >
      <span style="font-family: 微软雅黑"
        >3. Who do we share your information with?</span
      >
    </h4>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: left;
        widows: 2;
        orphans: 2;
      "
    >
      <span style="font-family: 微软雅黑"
        >• General,Loanism will not disclose any Information containing
        personal data (as defined under applicable law) to any third parties
        unless it is necessary and/or appropriate in order to provide LoanPlus’s
        products or services (provided, that, we may share limited personal data
        (as defined under applicable law) with select partners for research and
        development). Whenever practically feasible,</span
      ><br /><span style="font-family: 微软雅黑"
        >• This contact list information will be upload the server and will not
        share with any third party.</span
      ><br /><span style="font-family: 微软雅黑"
        >• You agree that we may, as necessary and/or appropriate for the
        purposes provided in section 2, transfer and disclose your information
        to:a subsidiary, affiliate, or parent company of Loanism
        (collectively, the “LoanPlus Group”);employees, subcontractors, agents,
        service providers, or associates of the Loanism Group (including
        directors and officers); intermediary, correspondent and agent banks,
        non-banks, quasi-banks or other financial institutions, clearinghouses,
        clearing or settlement systems, market counterparties, upstream
        withholding agents, licensed electronic or mobile wallet providers,
        credit reference agencies or credit bureaus;</span
      ><br /><span style="font-family: 微软雅黑"
        >• service providers with contractual or fiduciary relationships with
        Loanism (e.g., to facilitate transaction processing, fraud prevention,
        cloud data storage or data transfer);</span
      ><br /><span style="font-family: 微软雅黑"
        >• external collection agencies (to assist in the collection of any
        unpaid obligations to us);</span
      ><br /><span style="font-family: 微软雅黑">• tax authorities;</span
      ><br /><span style="font-family: 微软雅黑"
        >• a party in connection with any merger, acquisition, or sale of all or
        substantially all of the assets of Loanism and/or any company within
        within the Loanism Group;to other third parties to the extent
        extent (1) we have a duty to disclose or share your information in order
        comply with any legal obligation, (2) necessary or appropriate to
        enforce or apply any agreement with you, and/or (3) necessary or
        appropriate to protect the rights or safety of Loanism, the
        Loanism Group and/or our customers.</span
      ><br /><span style="font-family: 微软雅黑"
        >• The above parties may also process or disclose your information for
        the purposes set forth in section 2, so long as such processing or
        disclosure is in compliance with this Privacy Policy.</span
      ><br /><span style="font-family: 微软雅黑"
        >• Further, Loanism may also share your information with law
        enforcement or other government agencies in connection with a formal
        request, subpoena, court order, or similar legal procedure, or when we
        believe in good faith that disclosure is necessary to comply with the
        law, prevent physical harm or financial loss, to report suspected
        illegal activity, or to investigate violations of our agreements with
        you.</span
      ><br /><span style="font-family: 微软雅黑">• Referrals</span><br /><span
        style="font-family: 微软雅黑"
        >• To promote its products and services, Loanism administers and
        services a referral program (the “LoanPlus Referral Program”) for its
        current and future customers. If you choose to accept a referral from
        another Loanism customer and accept a Loanism loan in
        connection with that referral, Loanism will provide a bonus to
        the customer who refers you upon the successful repayment of your
        Loanism loan.</span
      ><br /><span style="font-family: 微软雅黑"
        >• If you choose to participate in the Loanism Referral Program as a
        referee, you specifically consent to the sharing of such anonymized
        information about your repayment status by accepting the referral,
        clicking on the embedded referral link, and subsequently applying for
        and accepting a Loanism loan through such referral.</span
      ><br /><span style="font-family: 微软雅黑"
        >• Sharing of your Information in connection with the Loanism Referral
        Program will always be limited to the information described above and
        will always be anonymized – Loanism will never share identifiable or
        personalized data about your repayment status (or any other personal
        data (as defined under applicable law) with any other customer or user
        or Loanism. More information on the Loanism Referral Program is
        available within the Loanism App.</span
      >
    </p>
    <h4
      style="
        margin-top: 14pt;
        margin-bottom: 14pt;
        text-align: left;
        widows: 2;
        orphans: 2;
      "
    >
      <span style="font-family: 微软雅黑"
        >4. For how long will we retain your Information?</span
      >
    </h4>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: left;
        widows: 2;
        orphans: 2;
      "
    >
      <span style="font-family: 微软雅黑"
        >Your Information will be stored or retained by Loanism and its
        transferees only for so long as necessary for the purposes listed in
        sections 2 and 10 of this Privacy Policy, and for a reasonable time
        thereafter for the delivery of our products and completion of our
        services. We may also retain your Information (i) as long as necessary
        to comply with any legal obligation; (ii) whenever such is authorized
        under the law; and (iii) for the establishment of legal defenses.</span
      >
    </p>
    <h4
      style="
        margin-top: 14pt;
        margin-bottom: 14pt;
        text-align: left;
        widows: 2;
        orphans: 2;
      "
    >
      <span style="font-family: 微软雅黑"
        >5. Automated decisions and profiling</span
      >
    </h4>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: left;
        widows: 2;
        orphans: 2;
      "
    >
      <span style="font-family: 微软雅黑"
        >• We may make certain decisions in relation to the provision of our
        products and services to you by using automated decision-making
        processes, without human involvement.</span
      ><br /><span style="font-family: 微软雅黑">• Lending decisions</span
      ><br /><span style="font-family: 微软雅黑"
        >• When you apply for a loan, we’ll use automated processing to decide
        whether to lend to you and/or make other decisions about your
        eligibility for our products and services. This automated processing
        enables us to provide rapid, responsive, and tailored credit services to
        customers who may not have credit histories, prior bank or other
        financial data, or income from formal sources.</span
      ><br /><span style="font-family: 微软雅黑"
        >• Our credit and underwriting models utilize machine-learning
        technology to process your Information and assess your creditworthiness.
        The associated processing of your Information is automated and little to
        no human intervention is involved. Using such automated processes to
        assess your creditworthiness means we may automatically decide that you
        may be ineligible for a loan or ineligible for a loan of a particular
        amount or tenure. Our credit and underwriting models are regularly
        tested to ensure they remain fair, accurate, and unbiased.</span
      ><br /><span style="font-family: 微软雅黑">• Detecting fraud</span
      ><br /><span style="font-family: 微软雅黑"
        >• Loanism also utilizes automated processes to detect, combat, and
        prevent fraud. Our fraud models may automatically decide that a certain
        individual poses a fraud or money laundering risk (e.g., if our
        processing reveals information or behavior consistent with money
        laundering or known fraudulent activity, if the activity is inconsistent
        with prior activity on our platform, or if an individual appears to be
        hiding their true identity). If our fraud models determine that
        processing of a transaction or approving a certain individual creates a
        risk of fraud, that individual’s access may be suspended or
        refused.</span
      ><br /><span style="font-family: 微软雅黑"
        >• Your rights in relation to automated decision-making</span
      ><br /><span style="font-family: 微软雅黑"
        >• If you apply for a loan and your loan is declined (or you are
        informed that you are ineligible for other or associated products or
        services) as a result of an automated credit, underwriting, or fraud
        decision, you may request that one of our underwriters review this
        decision. You may do so by contacting us at:
        nilwyservice@gmail.com</span
      >
    </p>
    <h4
      style="
        margin-top: 14pt;
        margin-bottom: 14pt;
        text-align: left;
        widows: 2;
        orphans: 2;
      "
    >
      <span style="font-family: 微软雅黑"
        >6. Your rights as a data subject</span
      >
    </h4>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: left;
        widows: 2;
        orphans: 2;
      "
    >
      <span style="font-family: 微软雅黑"
        >• You may contact us to exercise your rights at
        nilwyservice@gmail.com</span
      ><br /><span style="font-family: 微软雅黑"
        >• Rights to notice and consent</span
      ><br /><span style="font-family: 微软雅黑"
        >• We must provide you with certain information related to how we
        collect your Information, how we use your information (and our legal
        basis for doing so), who we share your Information with, where we
        obtained your information, and your rights as a data subject. This
        information is provided within the Loanism App and in this Privacy
        Policy.</span
      ><br /><span style="font-family: 微软雅黑">• Right to access</span
      ><br /><span style="font-family: 微软雅黑"
        >• You may ask for a copy of the personal data (as defined under
        applicable law) we hold concerning you (and your Information related to
        such personal data) unless providing some or all of it would adversely
        affect the rights and freedoms of others or applicable law requires that
        we do not comply with your request.</span
      ><br /><span style="font-family: 微软雅黑">• Right to rectification</span
      ><br /><span style="font-family: 微软雅黑"
        >• You may ask us to correct any information which you believe to be
        inaccurate. We will promptly update any such information. In connection
        with your request, you may be required to provide supporting evidence or
        other documentation so that we may verify the accuracy of the
        request.</span
      ><br /><span style="font-family: 微软雅黑"
        >• Right to erasure (“right to be forgotten”)</span
      ><br /><span style="font-family: 微软雅黑"
        >• You may ask us to erase your personal data (as defined under
        applicable law):</span
      ><br /><span style="font-family: 微软雅黑"
        >• If you believe it’s no longer necessary for us to retain such
        personal data;</span
      ><br /><span style="font-family: 微软雅黑"
        >• If you do not believe we have a legitimate ground for processing
        it;</span
      ><br /><span style="font-family: 微软雅黑"
        >• If you think we are using such personal data unlawfully; and/or</span
      ><br /><span style="font-family: 微软雅黑"
        >• If you think applicable law requires that we do so.</span
      ><br /><span style="font-family: 微软雅黑"
        >• Right to restrict processing</span
      ><br /><span style="font-family: 微软雅黑"
        >• You may ask us to stop using your personal data (as defined under
        applicable law):</span
      ><br /><span style="font-family: 微软雅黑"
        >• if you think such personal data is inaccurate;</span
      ><br /><span style="font-family: 微软雅黑"
        >• if you think it’s illegal for us to use such personal data;</span
      ><br /><span style="font-family: 微软雅黑"
        >• if you don’t want us to destroy such personal data because you need
        it for legal proceedings;</span
      ><br /><span style="font-family: 微软雅黑"
        >• if you’ve informed us that we don’t have a legitimate reason for
        using it and we’re considering your request.</span
      ><br /><span style="font-family: 微软雅黑"
        >• Right to data portability</span
      ><br /><span style="font-family: 微软雅黑"
        >• If we’re using your information on the basis of your consent or
        because we need it to carry out our contractual obligations to you, you
        can ask us to give you your personal data (as defined under applicable
        law) in a structured, commonly used, and machine-readable format or have
        it transmitted to another data controller.</span
      ><br /><span style="font-family: 微软雅黑">• Right to object</span
      ><br /><span style="font-family: 微软雅黑"
        >• You have a right to object whenever we are using your personal data
        (as defined under applicable law) on the basis of our legitimate
        interests (please see section 2 for the ways in which we rely on
        legitimate interests to use and process such personal data).</span
      ><br /><span style="font-family: 微软雅黑"
        >• Right to file a complaint</span
      ><br /><span style="font-family: 微软雅黑"
        >• You have the right to file a complaint with the relevant government
        agencies for any violation of your rights as a data subject, including
        the National Privacy Commission of the Philippines.</span
      ><br /><span style="font-family: 微软雅黑"
        >• Please note that there may be occasions when you wish to exercise
        your rights and we’re unable to agree to your request (e.g., because we
        have compelling legitimate grounds for using or processing your
        information or because we need to retain your information to comply with
        a legal obligation).</span
      >
    </p>
    <h4
      style="
        margin-top: 14pt;
        margin-bottom: 14pt;
        text-align: left;
        widows: 2;
        orphans: 2;
      "
    >
      <span style="font-family: 微软雅黑"
        >7. Your rights as a data subject</span
      >
    </h4>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: left;
        widows: 2;
        orphans: 2;
      "
    >
      <span style="font-family: 微软雅黑"
        >We may make certain decisions in relation to the provision of our
        products and services to you by using automated decision-making
        processes, without human involvement.</span
      ><br /><span style="font-family: 微软雅黑">• Lending decisions</span
      ><br /><span style="font-family: 微软雅黑"
        >• When you apply for a loan, we’ll use automated processing to decide
        whether to lend to you and/or make other decisions about your
        eligibility for our products and services. This automated processing
        enables us to provide rapid, responsive, and tailored credit services to
        customers who may not have credit histories, prior bank or other
        financial data, or income from formal sources.</span
      ><br /><span style="font-family: 微软雅黑"
        >• Our credit and underwriting models utilize machine-learning
        technology to process your Information and assess your creditworthiness.
        The associated processing of your Information is automated and little to
        no human intervention is involved. Using such automated processes to
        assess your creditworthiness means we may automatically decide that you
        may be ineligible for a loan or ineligible for a loan of a particular
        amount or tenure. Our credit and underwriting models are regularly
        tested to ensure they remain fair, accurate, and unbiased.</span
      ><br /><span style="font-family: 微软雅黑">• Detecting fraud</span
      ><br /><span style="font-family: 微软雅黑"
        >• Loanism also utilizes automated processes to detect, combat, and
        prevent fraud. Our fraud models may automatically decide that a certain
        individual poses a fraud or money laundering risk (e.g., if our
        processing reveals information or behavior consistent with money
        laundering or known fraudulent activity, if the activity is inconsistent
        with prior activity on our platform, or if an individual appears to be
        hiding their true identity). If our fraud models determine that
        processing of a transaction or approving a certain individual creates a
        risk of fraud, that individual’s access may be suspended or
        refused.</span
      ><br /><span style="font-family: 微软雅黑"
        >• Your rights in relation to automated decision-making</span
      ><br /><span style="font-family: 微软雅黑"
        >• If you apply for a loan and your loan is declined (or you are
        informed that you are ineligible for other or associated products or
        services) as a result of an automated credit, underwriting, or fraud
        decision, you may request that one of our underwriters review this
        decision. You may do so by contacting us at:
        nilwyservice@gmail.com.</span
      >
    </p>
    <h4
      style="
        margin-top: 14pt;
        margin-bottom: 14pt;
        text-align: left;
        widows: 2;
        orphans: 2;
      "
    >
      <span style="font-family: 微软雅黑"
        >8. Consequences of not providing us with your Information</span
      >
    </h4>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: left;
        widows: 2;
        orphans: 2;
      "
    >
      <span style="font-family: 微软雅黑"
        >You are not required to provide us with your Information or any
        associated personal data (as defined under applicable) and you may
        withdraw your consent from the use or processing of such information or
        personal data. However, if you do so, we may be unable to provide our
        current or future products and services to you and we reserve the right
        to terminate our relationship with you, as permitted under applicable
        law. Further, to the extent we have a legitimate interest in retaining
        your Information and/or associated personal data (as defined under
        applicable law), we may do so. For example, if you have requested that
        we erase your Information or associated personal data (as defined under
        applicable law), but you have not repaid your loan with Loanism, we may
        retain your Information or associated personal data (as defined under
        applicable law), in order to continue collection efforts.</span
      >
    </p>
    <h4
      style="
        margin-top: 14pt;
        margin-bottom: 14pt;
        text-align: left;
        widows: 2;
        orphans: 2;
      "
    >
      <span style="font-family: 微软雅黑">9. Consent and Authorization</span>
    </h4>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: left;
        widows: 2;
        orphans: 2;
      "
    >
      <span style="font-family: 微软雅黑"
        >• By downloading the Loanism App, you:</span
      ><br /><span style="font-family: 微软雅黑"
        >• accept the terms of this Privacy Policy;</span
      ><br /><span style="font-family: 微软雅黑"
        >• give Loanism consent to collect, use, share, or otherwise process
        your information, which may include personal information, sensitive
        personal information or privileged information (in each case, as defined
        under applicable law), as outlined in this Privacy Policy; certify that
        all information you have provided and will provide to Loanism is true
        and correct to the best of your knowledge; authorize Loanism to
        verify/investigate the accuracy of your Information; and acknowledge
        that Loanism may be required to disclose your Information to the Bangko
        Sentral ng Pilipinas, Anti-Money Laundering Council, credit bureaus,
        and/or any other governmental body, in compliance with its legal
        obligations.</span
      >
    </p>
    <h4
      style="
        margin-top: 14pt;
        margin-bottom: 14pt;
        text-align: left;
        widows: 2;
        orphans: 2;
      "
    >
      <span style="font-family: 微软雅黑">10. General</span>
    </h4>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: left;
        widows: 2;
        orphans: 2;
      "
    >
      <span style="font-family: 微软雅黑"
        >• If you need to contact us, please do so at:</span
      ><br /><span style="font-family: 微软雅黑"
        >• To improve and continue our services to you, this Privacy Policy may
        be updated from time to time. We will notify you prior to any updates to
        this Privacy Policy, either within the Loanism App</span
      >
    </p>
    <p
      style="
        margin-top: 0pt;
        margin-bottom: 0pt;
        text-align: center;
        widows: 2;
        orphans: 2;
      "
    >
      <span style="font-family: 微软雅黑; font-weight: bold"
        >Owned &amp; Operated by Nilwy.</span
      >
    </p>
    <p style="margin-top: 0pt; margin-bottom: 0pt">
      <span style="-aw-import: ignore">&#xa0;</span>
    </p>
  </div>
</template>

<script>
export default {
  name: 'privacy',
  mounted() {
    // 替换头部标题
    document.title = 'Privacy Policy'
  }
}
</script>

<style lang="less" scoped>
.privacy {
  padding: 15px;
  .right {
    text-align: right;
  }
  .title {
    font-size: 18px;
    color: #333;
    text-align: center;
    line-height: 36px;
    padding: 10px;
    font-weight: 600;
  }
  .content {
    text-indent: 25px;
  }
  .content em {
    font-style: normal;
    text-decoration: underline;
  }
  .content b {
    color: #333;
    text-decoration: underline;
  }
  .bold {
    font-weight: bold;
  }
  p {
    font-size: 14px;
    color: #333;
    line-height: 23px;
    margin-bottom: 7px;
    word-break: break-all;
  }
}
</style>
